import type { LinkItem } from "~~/models/links";
import type {
  CustomLinkRecord,
  IconLinkRecord,
  ImageLinkRecord,
  LinkColumnRecord,
  LinkSetRecord,
  MenuLinkRecord,
  PageLinkRecord,
  PanelLinkRecord,
  SidePanelRecord,
  TeaserRecord,
} from "~~/server/graphql/datocms/generated";

export function getLinkForType(
  item:
    CustomLinkRecord |
    IconLinkRecord |
    ImageLinkRecord |
    LinkColumnRecord |
    LinkSetRecord |
    MenuLinkRecord |
    TeaserRecord |
    // TitleAndTextLinkRecord |
    PageLinkRecord |
    PanelLinkRecord,
  localePath: any,
  target?: string,
): LinkItem {
  if (item?._modelApiKey === "custom_link") {
    const customLink = item as CustomLinkRecord;

    return {
      id: customLink.id,
      label: customLink.text,
      href: customLink.optLinkUrl,
      target: target ?? getTarget(customLink.optLinkUrl || "/"),
    };
  }

  if (item?._modelApiKey === "icon_link") {
    const iconLink = item as IconLinkRecord;
    const iconLinkItem = getLinkForType(iconLink.linkItem, localePath);

    if (iconLinkItem) {
      iconLinkItem.iconName = iconLink.iconName;
    }

    return iconLinkItem;
  }

  if (item?._modelApiKey === "image_link") {
    const imageLink = item as ImageLinkRecord;
    const imageLinkItem = getLinkForType(imageLink.linkItem, localePath);

    if (imageLinkItem) {
      imageLinkItem.image = imageLink.image;
    }

    return imageLinkItem;
  }

  if (item?._modelApiKey === "panel_link") {
    const menuLink = item as PanelLinkRecord;
    const menuLinkItem = getLinkForType(menuLink.mainLink, localePath);

    if (menuLinkItem) {
      menuLinkItem.subLinks = [
        menuLink.subLinksColumn1.map(l => getLinkForType(l, localePath)),
        menuLink.subLinksColumn2.map(l => getLinkForType(l, localePath)),
        menuLink.subLinksColumn3.map(l => getLinkForType(l, localePath)),
      ];
    }

    if (menuLink.callToAction) {
      menuLinkItem.callToAction = getLinkForType(menuLink.callToAction, localePath);
    }

    if (menuLink.sidePanel) {
      menuLinkItem.sidePanel = getSidePanelLink(menuLink.sidePanel, localePath);
    }

    return menuLinkItem;
  }

  if (item?._modelApiKey === "menu_link") {
    const menuLink = item as MenuLinkRecord;
    const menuLinkItem = getLinkForType(menuLink.linkItem, localePath);

    if (menuLinkItem) {
      menuLinkItem.subLinks = [menuLink.subLinkItems.map(l => getLinkForType(l, localePath))];
    }

    return menuLinkItem;
  }

  if (item?._modelApiKey === "page_link") {
    const pageLink = item as PageLinkRecord;
    const pageUrl = getUrl(pageLink.link!.slug, pageLink.link!._modelApiKey, localePath);

    return {
      id: pageLink.id,
      label: pageLink.text,
      href: pageUrl,
      target: target ?? getTarget(pageUrl),
    };
  }

  if (item?._modelApiKey === "link_column") {
    const column = item as LinkColumnRecord;

    const columnItem: LinkItem = {
      id: column.id,
      label: "",
      subLinks: [column.linkSet.map(l => getLinkForType(l, localePath))], // map to correct 2D array
    };

    return columnItem;
  }

  if (item?._modelApiKey === "link_set") {
    const linkSet = item as LinkSetRecord;

    const linkSetItem: LinkItem = {
      id: linkSet.id,
      label: linkSet.setTitle,
      subLinks: [linkSet.linkItems.map(l => getLinkForType(l, localePath))],
    };

    return linkSetItem;
  }

  return { id: "0", label: `Unknown item (${item})` };
}

function getSidePanelLink(
  item: SidePanelRecord,
  localePath: any,
): LinkItem {
  const sidePanelItem = getLinkForType(item.link, localePath);

  if (sidePanelItem) {
    sidePanelItem.image = item.image;
    sidePanelItem.title = item.title;
  }

  return sidePanelItem;
}

export function getTarget(path: string) {
  try {
    if (path.startsWith("http")) {
      const pathUrl = new URL(path);
      const requestUrl = useRequestURL();

      if (pathUrl.host !== requestUrl.host) {
        return "_blank";
      }
    }

    return "_self";
  }
  catch {
    return "_blank";
  }
}

export function getUrl(path: string, apiKey: string, localePath: any) {
  if (path.indexOf(":") > 0) {
    return path;
  }

  switch (apiKey) {
    case "category_page":
      return localePath({ name: "categories-slug", params: { slug: path } });
    case "legal_page":
      return localePath(`/legal/${path}`);
    case "series_page":
      return localePath({ name: "series-slug", params: { slug: path } });
    case "solution_page":
      return localePath({ name: "solutions-slug", params: { slug: path } });
  }

  return localePath(`/${path}`);
}
